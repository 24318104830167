import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TranscriptAccordion from "../../components/shared/transcript-accordion"
import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"

import akbcares from "../../images/AkebiaCares-logo.png"
import PrescribeIcon from "../../images/icons/icon-callout-hp-dosing.png"
import ChartActive from "../../images/hp/efficacy/chart-active-control.png"
import ChartPlacebo from "../../images/hp/efficacy/chart-placebo.png"
import ChartExploratoryEndpoint from "../../images/hp/efficacy/chart-exploratory-endpoint.png"
import Chart61 from "../../images/hp/efficacy/group-61.png"
import Chart62 from "../../images/hp/efficacy/group-62.png"
import Chart71 from "../../images/hp/efficacy/group-71.png"

const anchorClick = event => {
	event.preventDefault();
	event.stopPropagation();

	const bounds = document.getElementById('transcript').getBoundingClientRect();
	let scrollPosition = window.pageYOffset + bounds.top;

	// Adjust for the height of the sticky header 
	let stickyHdr;
	setTimeout(function () {
		if (window.matchMedia("(min-width: 768px)").matches) {
			stickyHdr = document.getElementById('site-navigation').getBoundingClientRect().height;
		}
		else {
			stickyHdr = document.getElementById('hpp-navigation').getBoundingClientRect().height;
		}
		scrollPosition = scrollPosition - stickyHdr;
		window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
		// open accordion pls

		let studyDesignBtn = document.getElementById('study-design');
		let viewDataTablesBtn = document.getElementById('panel-header');


		if (!studyDesignBtn.classList.contains("Mui-expanded")) {
			studyDesignBtn.click();
		}


		if (viewDataTablesBtn.classList.contains("Mui-expanded")) {
			viewDataTablesBtn.click();
		}


	}, 100);
};

const EfficacyPage = ({ location }) => (
	<HPPLayout
		location={location}
		locationHostName={location.hostname}
		jobCode="PP-AUR-US-1407 (v7.0)"
		jobCodeDate="07/23"
		references={[
			<>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.</>,
			<>Data on File 1, Akebia Therapeutics, Inc.</>,
			<>Umanath K, Sika M, Niecestro R, et al; for Collaborative Study Group. Rationale and study design of a three period, 58-week trial of ferric citrate as a phosphate binder in patients with ESRD on dialysis. <em>Hemodial Int.</em> 2013;17(1):67-74. doi:10.1111/j.1542-4758.2012.00711.x</>,
			<>Data on File 31, Akebia Therapeutics, Inc.</>
		]} >
		<Helmet>
			<html lang="en" />
			<link rel="canonical" href="https://www.auryxiahcp.com/hyperphosphatemia/ferric-citrate-efficacy" />
			<body className="hpp" id="hpp-hcp-efficacy" />
		</Helmet>

		<Seo title="Efficacy" description="Read about treatment with AURYXIA® (ferric citrate) tablets and examine pivotal trial data and trial design. Read Important Safety Information and Full Prescribing information on this page." />

		<section id="hero" className="center">
			<div className="flex-row">
				<div>
					<h1>Strong and sustained phosphorus reductions for more than a year of treatment <span className="nowrap">(56 weeks)<sup>1,2</sup></span></h1>
					<button className="cta hpp" onClick={anchorClick}><span>SEE TRIAL DESIGN</span></button>
				</div>
			</div>
		</section>

		<section id="sustained" className="flood-hp col-10 center">
			<div className="flex-row">
				<div>
					<h2 className="hpp">AURYXIA was effective in getting patients to goal when used as a monotherapy<sup>1,2</sup></h2>

					<div id="charts" className="flex-row"
						data-sal="slide-up"
						data-sal-duration="600"
						data-sal-delay="200"
						data-sal-ease="ease-out-expo">
						<div>
							<h3 className="hpp">AURYXIA had similar reductions in serum phosphorus compared to Active Control<sup>1,2</sup>*</h3>

							<p><strong>Secondary endpoint</strong>: treatment difference of 0.02 mg/dL at Week 52 (<em>P</em>=0.89)</p>

							<figure>
								<figcaption>
									<p className="circle auryxia">AURYXIA (n=292)</p>
									<p className="circle other">Active Control (n=149)</p>
								</figcaption>
								<img src={ChartActive} alt="Line graph showing AURYXIA® (ferric citrate) effect on serum phosphorus levels compared to Active Control." />
							</figure>

						</div>
						<div className="small-only">
							<p>AURYXIA Arm Re-randomization</p>
						</div>
						<div>

							<h3 className="hpp">AURYXIA maintained significant reductions in serum phosphorus compared to placebo<sup>1,2</sup></h3>
							<p><strong>Primary endpoint</strong>: treatment difference of -2.18 mg/dL at Week 56 (<em>P</em>&#x003C;0.0001)</p>
							<figure>

								<figcaption>
									<p className="circle auryxia">AURYXIA (n=96)</p>
									<p className="circle other">Placebo (n=96)</p>
								</figcaption>

								<img src={ChartPlacebo} alt="Line graph showing AURYXIA® (ferric citrate) effect on serum phosphorus levels compared to placebo." />
							</figure>
						</div>
						<div className="full-width large-only">
							<p>AURYXIA Arm Re-randomization</p>
						</div>
						<p className="footnote center">
							*Active Control=sevelamer carbonate and/or calcium acetate.
						</p>
					</div>



				</div>
			</div>
		</section>

		<section id="phosphorous" className="col-10 center">
			<div className="flex-row"
				data-sal="zoom-in"
				data-sal-duration="600"
				data-sal-delay="200"
				data-sal-ease="ease-out-expo">
				<div>
					<h2 className="hpp">AURYXIA maintained mean serum phosphorus levels between 3.5-5.5 mg/dL after a year of treatment (up to 56 weeks)<sup>2</sup></h2>
					<p>Patients on AURYXIA had a mean serum phosphorus level of 7.41 mg/dL at baseline and 4.88 mg/dL at Week 56<sup>2</sup></p>
				</div>
			</div>
		</section>

		<section id="transcript">
			<TranscriptAccordion
				siteAudience="hpp"
				transcriptTitle="VIEW DATA TABLES"
				transcriptContent={<>
					<div className="table-wrap">
						<h4 className="hpp">Ferric citrate had similar reductions in serum phosphorus compared to Active Control<sup>2</sup>*</h4>
						<table>
							<tbody>
								<tr>
									<td>

									</td>
									<th>
										<strong>FERRIC CITRATE</strong>
									</th>
									<th>
										<strong>ACTIVE CONTROL</strong>
									</th>
									<td>

									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
									<th>
										<strong>Mean Serum<br />Phosphorus</strong> (mg/dL)
									</th>
									<th>
										<strong>Mean Serum <br />Phosphorus</strong> (mg/dL)
									</th>
									<th>
										<strong>Change From
											Baseline:</strong> Treatment Difference <span className="nowrap">P-Value</span>
									</th>
								</tr>
								<tr>
									<td>
										<strong>Baseline</strong>
										<br />(n)
									</td>
									<td>
										<strong>7.41</strong>
										<br />(281)
									</td>
									<td>
										<strong>7.56</strong>
										<br />(146)
									</td>
									<td>

									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 12</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.38</strong>
										<br />(281)
									</td>
									<td>
										<strong>5.34</strong>
										<br />(146)
									</td>
									<td>
										0.65
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 24</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.29</strong>
										<br />(281)
									</td>
									<td>
										<strong>5.51</strong>
										<br />(146)
									</td>
									<td>
										0.24
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 36</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.23</strong>
										<br />(281)
									</td>
									<td>
										<strong>5.31</strong>
										<br />(146)
									</td>
									<td>
										0.76
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 48</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.34</strong>
										<br />(281)
									</td>
									<td>
										<strong>5.51</strong>
										<br />(146)
									</td>
									<td>
										0.39
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 52</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.37</strong>
										<br />(281)
									</td>
									<td>
										<strong>5.38</strong>
										<br />(146)
									</td>
									<td>
										0.89
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p className="footnote center">*Active Control=sevelamer carbonate and/or calcium acetate.</p>
					<br />
					<div className="table-wrap">
						<h4 className="hpp">Ferric citrate maintained significant reductions in serum phosphorus compared to&nbsp;placebo<sup>1,2</sup></h4>
						<table>
							<tbody>
								<tr>
									<td>

									</td>
									<th>
										<strong>FERRIC CITRATE</strong>
									</th>
									<th>
										<strong>PLACEBO</strong>
									</th>
									<td>

									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
									<th>
										<strong>Mean Serum<br /> Phosphorus</strong> (mg/dL)
									</th>
									<th>
										<strong>Mean Serum<br />  Phosphorus</strong> (mg/dL)
									</th>
									<th className="light">
										<strong>Change From Baseline:</strong> Treatment Difference P-Value
									</th>
								</tr>
								<tr>
									<td>
										<strong>Week 52</strong>
										<br />(n)
									</td>
									<td>
										<strong>5.12</strong>
										<br />(91)
									</td>
									<td>
										<strong>5.44</strong>

										<br />(91)
									</td>
									<td>

									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 53</strong>
										<br />(n)
									</td>
									<td>

										<strong>4.89</strong>


										<br />(81)
									</td>
									<td>
										<strong>6.57</strong>

										<br />(87)
									</td>
									<td>
										&#x003C;0.0001
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 54</strong>
										<br />(n)
									</td>
									<td>
										<strong>4.76</strong>


										<br />(90)
									</td>
									<td>
										<strong>6.88</strong>


										<br />(90)
									</td>
									<td>
										&#x003C;0.0001
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 55</strong>
										<br />(n)
									</td>
									<td>
										<strong>4.73</strong>


										<br />(91)
									</td>
									<td>
										<strong>6.88</strong>


										<br />(91)
									</td>
									<td>
										&#x003C;0.0001
									</td>
								</tr>
								<tr>
									<td>
										<strong>Week 56</strong>
										<br />(n)
									</td>
									<td>
										<strong>4.88</strong>


										<br />(91)
									</td>
									<td>
										<strong>7.23</strong>


										<br />(91)
									</td>
									<td>
										&#x003C;0.0001
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</>}
			/>

			<TranscriptAccordion
				siteAudience="hpp"
				transcriptTitle="SEE TRIAL DESIGN"
				idName="study-design"
				transcriptContent={<>
					<p><strong>Trial design<sup>1,3</sup></strong></p>
					<p>A multicenter, randomized, open‐label trial evaluated the ability of AURYXIA to lower serum phosphorus in patients with CKD on dialysis over 56 weeks. Eligible patients had serum ferritin &#x003C;1000 ng/mL, serum TSAT &#x003C;50%, and serum phosphorus ≥2.5 and ≤8.0 mg/dL at the screening visit. The safety and efficacy of AURYXIA were studied in the 52‐week active‐controlled period (AURYXIA n=292, Active Control n=149), then AURYXIA patients were re‐randomized to either continue AURYXIA treatment or receive placebo during the placebo‐controlled period, weeks 52‐56 (AURYXIA n=96, placebo n=96). The primary endpoint was the change in serum phosphorus from baseline (Week 52) to Week 56 between AURYXIA and placebo. The key secondary endpoint was the change in serum phosphorus from baseline (Week 0) to Week 52 between AURYXIA and Active Control.</p>
					<p className="footnote margint-4">CKD=chronic kidney disease; TSAT=transferrin saturation; Active Control=sevelamer carbonate and/or calcium acetate.</p>

				</>}
			/>

			<TranscriptAccordion
				siteAudience="hpp"
				transcriptTitle="SEE ADDITIONAL DATA POINTS"
				transcriptContent={<>
					<h2 className="hpp">Percentage of patients achieving serum phosphorus levels of ≤5.5 mg/dL<sup>4†</sup></h2>
					<div className="MuiAccordionDetails-root-innerdiv">
						<img className="desktop" src={ChartExploratoryEndpoint} alt="Exploratory Endpoint" />
						<div className="mobile">
							<h5>ACTIVE CONTROLLED PERIOD (Baseline to Week 52) AURYXIA (n=281), Active Control (n=146)</h5>
							<img src={Chart61} alt="Exploratory Endpoint" />
							<p className="avenirFont">AURYXIA patients achieved <strong>phosphorus reductions to</strong> ≤5.5 mg/dL by Week 12<sup>4</sup></p>
							<img src={Chart62} alt="Exploratory Endpoint" />
							<p className="avenirFont">AURYXIA patients sustained <strong>phosphorus reductions to</strong> ≤5.5 mg/dL through Week 52<sup>1,2,4</sup></p>
							<h5>PLACEBO CONTROLLED PERIOD (Week 52 to Week 56) AURYXIA (n=91), Placebo (n=91)</h5>
							<img src={Chart71} alt="Exploratory Endpoint" />
							<p className="avenirFont">AURYXIA patients sustained phosphorus reductions to ≤5.5 mg/dL through Week 56<sup>1,2,4</sup></p>
							<p><strong><sup className="hang">†</sup><span className="gray_italic_font">Based on exploratory endpoint data. The statistical tests performed for the exploratory endpoint data were not adjusted for potential errors and were for descriptive purposes only.</span></strong></p>
						</div>
					</div>
				</>}
			/>
			
			<p className="center"><Link to="/hyperphosphatemia/ferric-citrate-reactions/" className="gradient-link hpp">EXPLORE SAFETY PROFILE <ChevronRightIcon viewBox="6 6 12 12" /></Link></p>
			<br />
		</section>



		<EopCalloutCards
			siteAudience="hpp"
			callOneClass="highlight"
			callOneLink={"/hyperphosphatemia/dosing/"}
			callOneContent={
				<>
					<img src={PrescribeIcon} alt="" width="98" height="98" />
					<h3>Ready to prescribe?</h3>
					<p>The first step to achieve the proven efficacy of AURYXIA is to start with the recommended dose. Find out how to dose and titrate AURYXIA.</p>
					<button className="cta hpp"><span>GET DOSING INFORMATION</span></button>
				</>
			}

			callTwoClass="akebia-cares"
			callTwoLink={"https://www.akebiacareshcp.com/"}
			callTwoContent={
				<>
					<img src={akbcares} alt="AkebiaCares" height="81" />
					<h3>Your partner in helping patients access the medication they need</h3>
					<p>AkebiaCares supports your patients with information and resources that can help people start and stay on AURYXIA.</p>
					<button className="cta akb"><span>FIND SUPPORT OPTIONS</span></button>

				</>
			}
		/>

	</HPPLayout>

)

export default EfficacyPage